import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';
import './Leaders.css';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import RowInTheTable from './../RowInTheTable/RowInTheTable';
import classes from './Leaders.css'
const GET_LAWYERS_ORDER_BY_SEMRUSH = gql `
    query ($keyword: String, $limit: Int){
        lawyers(orderBy: "semrushValue", active: true, first: $limit, searchKeyword: $keyword) {
            firmName
            semrushValue
            keyword
            rankingKeywords
            serpPage
            website
            semrushLink
            semrushValueChange
            rankingKeywordsChange
        }
    }
`;



const StyledTableCellHeader = withStyles((theme) => ({
    root: {
        background: 'none', 
        color: '#ff1100',
        border: 'none',
    }
}))(TableCell);
  
const StyledTableRowHeader = withStyles((theme) => ({
    root: {
        background: 'none',
        border: 'none',
    },
}))(TableRow);

export default function Leaders(props) {
    
    let recordsCount = null;
    let kwrd = null;
    if (props.state.keyword === ""){ recordsCount = 50; }
    else {
        kwrd = props.state.keyword;
    }
    return (
        <Query query={GET_LAWYERS_ORDER_BY_SEMRUSH} variables={{ keyword: kwrd, limit: recordsCount}}>
            {({ loading, error, data }) => {
                if (loading){ return <LinearProgress color="secondary" /> }
                if (error){ return `Error! ${error}` }
                if (props.state.firmName !== ""){
                    data.lawyers.map((row, index) => {
                        if (row.firmName === props.state.firmName || row.website.includes(props.state.firmName)){ 
                            if(props.pageSetted === false){
                                let page = Math.trunc((index) / props.rowsPerPage);
                                props.setPage(page);
                                props.setPageStatus(true);
                            }
                            props.changeFirmPosition(index + 1); 
                        }
                    });
                }
                props.setLength(data.lawyers.length);
                return (
                    <div className={classes.wrapper}>
                    <TableContainer elevation={3} style={{ borderRadius: 5 }}>
                        <Table aria-label="customized table" title="Leaders" style={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }}>
                            <StyledTableRowHeader>
                            <StyledTableCellHeader><strong>#</strong></StyledTableCellHeader>
                                <StyledTableCellHeader><strong>Firm Name</strong></StyledTableCellHeader>
                                <StyledTableCellHeader><strong>SemRush Value</strong></StyledTableCellHeader>
                                <StyledTableCellHeader><strong>Keyword</strong></StyledTableCellHeader>
                                <StyledTableCellHeader><strong>Ranking Keywords</strong></StyledTableCellHeader>
                                <StyledTableCellHeader><strong>SERP Page</strong></StyledTableCellHeader>
                                <StyledTableCellHeader><strong>Website</strong></StyledTableCellHeader>
                            </StyledTableRowHeader>
                            <TableBody style={{ background: "none" }}>
                            {data.lawyers.slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage).map((row, index) => (
                                <RowInTheTable key={index} setPageStatus={props.setPageStatus} state={props.state} index={props.page * props.rowsPerPage + index} row={row} current={props.state.firmName} addSearch={props.addSearch} changeSearchFirmName={ props.changeSearchFirmName } changeSearchKeyword={ props.changeSearchKeyword }/>
                            ))}
                            </TableBody>
                        </Table>
                        
                    </TableContainer>
                    
                        </div>
                );}}
        </Query>
    );
}