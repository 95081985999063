import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { numberWithCommas }  from './../../functions/functions';
import classes from './RowInTheTable.module.css';
import { withStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
const StyledTableCell = withStyles((theme) => ({
    root: {
        border: 'none',
        color: 'white',
        background: 'rgba( 255, 255, 255, 0.10 )',
        ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
            background: '#464646',
        }
    }
}))(TableCell);

const StyledTableCell1 = withStyles((theme) => ({
    root: {
        border: 'none',
        color: 'white',
        background: 'rgba( 255, 255, 255, 0.10 )',
        borderRadius: '5px 0 0 5px',
        ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
            background: '#464646',
        }
    }
}))(TableCell);

const StyledTableCell2 = withStyles((theme) => ({
    root: {
        border: 'none',
        color: 'white',
        background: 'rgba( 255, 255, 255, 0.10 )',
        borderRadius: '0 5px 5px 0',
        ['@media (max-width:960px)']: { // eslint-disable-line no-useless-computed-key
            background: '#464646',
        }
    }
}))(TableCell);
  



export default function RowInTheTable(props) {
    function handleClickOnFirmName(firmName){
        props.changeSearchFirmName(firmName, false);
        props.setPageStatus(false);
        props.addSearch();
    }
    function handleClickOnKeyword(keyword){
        props.changeSearchKeyword(keyword, false);
        props.setPageStatus(false);
        props.addSearch();
    }
    let rank = props.index + 1;
    let isCurrent = false;
    if (props.current !== ""){
        if (props.current === props.row.firmName || props.row.firmName.includes(props.current)){
            isCurrent = true;
        }
        else if (props.current === props.row.website || props.row.website.includes(props.current)){
            isCurrent = true;
        }
    }
    if (isCurrent) {
        return (<TableRow key={props.row.name} className={classes.row} style={{ background: 'linear-gradient(90deg, #F03131 0%, #F05F31 100%)' }}>
            <StyledTableCell1> 
                {rank}
            </StyledTableCell1>
            <StyledTableCell> 
                {props.row.firmName}
            </StyledTableCell>
            <StyledTableCell><a target="_blank" rel="noreferrer" title="SemRush Link" className={classes.semrush__link__current} href={props.row.semrushLink}>
                ${numberWithCommas(props.row.semrushValue)}
                {props.row.semrushValueChange < 0 && <ArrowDownwardIcon style={{ color: "black", position: 'relative', top:2, left: 4, fontSize:14 }}/>}
                {props.row.semrushValueChange > 0 && <ArrowUpwardIcon style={{ color: "black", position: 'relative', top:2, left: 4, fontSize:14 }} />}
            </a></StyledTableCell>
            <StyledTableCell>
                
                <button className={classes.transparentButton__current} onClick={() => {handleClickOnKeyword(props.row.keyword);}}>{props.row.keyword}</button>
            </StyledTableCell>
            <StyledTableCell><a target="_blank" rel="noreferrer" title="SemRush Link" className={classes.semrush__link__current} href={props.row.semrushLink}>{numberWithCommas(props.row.rankingKeywords)}
                {props.row.rankingKeywordsChange < 0 && <ArrowDownwardIcon style={{ color: "black", position: 'relative', top:2, left: 4, fontSize:14 }}/>}
                {props.row.rankingKeywordsChange > 0 && <ArrowUpwardIcon style={{ color: "black", position: 'relative', top:2, left: 4, fontSize:14 }} />}
            </a></StyledTableCell>
            <StyledTableCell>{props.row.serpPage}</StyledTableCell>
            <StyledTableCell2>{props.row.website}</StyledTableCell2>
        </TableRow>);
    }
    return(
        <TableRow key={props.row.name} className={classes.row}>
            <StyledTableCell1> 
                {rank}
            </StyledTableCell1>
            <StyledTableCell>
                <button className={classes.transparentButton} onClick={() => {handleClickOnFirmName(props.row.firmName);}}>{props.row.firmName}</button>
            </StyledTableCell>
            <StyledTableCell><a target="_blank" rel="noreferrer" title="SemRush Link" className={classes.semrush__link} href={props.row.semrushLink}>
                ${numberWithCommas(props.row.semrushValue)}
                {props.row.semrushValueChange < 0 && <ArrowDownwardIcon style={{ color: "red", position: 'relative', top:2, left: 4, fontSize:14 }}/>}
                {props.row.semrushValueChange > 0 && <ArrowUpwardIcon style={{ color: "green", position: 'relative', top:2, left: 4, fontSize:14 }} />}
            </a></StyledTableCell>
            <StyledTableCell>
                <button className={classes.transparentButton} onClick={() => {handleClickOnKeyword(props.row.keyword);}}>{props.row.keyword}</button>
                
            </StyledTableCell>
            <StyledTableCell><a target="_blank" rel="noreferrer" title="SemRush Link" className={classes.semrush__link} href={props.row.semrushLink}>{numberWithCommas(props.row.rankingKeywords)}
                {props.row.rankingKeywordsChange < 0 && <ArrowDownwardIcon style={{ color: "red", position: 'relative', top:2, left: 4, fontSize:14 }}/>}
                {props.row.rankingKeywordsChange > 0 && <ArrowUpwardIcon style={{ color: "green", position: 'relative', top:2, left: 4, fontSize:14 }} />}
            </a></StyledTableCell>
            <StyledTableCell>{props.row.serpPage}</StyledTableCell>
            <StyledTableCell2>{props.row.website}</StyledTableCell2>
        </TableRow>
    );
}